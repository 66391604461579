<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">전화 상담이력관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class>
          <div class="form-inp sm">
            <v-select
              v-model="selected_channel"
              :items="aspCustList"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              label="회사구분"
              v-on:change="setSrhCnslType()"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="dates[0]"
                no-title
                dark
                color="#F7AFAE"
                name="startDate"
                @change="startDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                v-model="dates[1]"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                :min="dates[0]"
                name="endDate"
                @change="endDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn outlined class="btn-default" @click="menu1 = false"
                >확인</v-btn
              >
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="calltypcd"
              :items="mixin_common_code_get(this.common_code, 'PLT007', '전체')"
              outlined
              hide-details
              label="콜유형"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd"
              :items="srhCnslTypCd"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형1"
              @change="changeCounselTypeKey(selected_channel, cnsltypcd, '1')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd2"
              :items="srhCnslTypCd2"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형2"
              @change="changeCounselTypeKey(selected_channel, cnsltypcd2, '2')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd3"
              :items="srhCnslTypCd3"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형3"
              @change="changeCounselTypeKey(selected_channel, cnsltypcd3, '3')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd4"
              :items="srhCnslTypCd4"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형4"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="mt-2">
            <v-text-field
              class="form-inp sm"
              name="userId"
              label="고객 ID"
              v-model="userId"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNm"
              label="고객 이름"
              v-model="userNm"
              outlined
              hide-details
            ></v-text-field>
            <!-- 조회 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnPopupCus')"
              dark
              small
              class="btn-main-search pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
              @click="btnCus(), showDialog('MP01')"
            >
              <v-icon small class="svg-main-searh white"></v-icon>
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnInitCus')"
              dark
              small
              class="btn-main-refresh pa-0 ml-2"
              min-width="32px"
              height="32px"
              @click="clear0"
              plain
            >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
            <div class="form-inp sm ml-2">
              <v-select
                :items="userGroup"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="사용자그룹"
                placeholder="선택하세요"
                v-model="selected_userGroup"
              ></v-select>
            </div>
            <v-text-field
              class="form-inp sm ml-2"
              name="userId1"
              label="사용자 ID"
              v-model="userId1"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNm1"
              label="사용자 이름"
              v-model="userNm1"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNick1"
              label="사용자 닉네임"
              v-model="userNick1"
              outlined
              hide-details
            ></v-text-field>
            <!-- 조회 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')"
              dark
              small
              class="btn-main-search pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
              @click="btnUser(), showDialog('MP02')"
            >
              <v-icon small class="svg-main-searh white"></v-icon>
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')"
              dark
              small
              class="btn-main-refresh pa-0 ml-2"
              min-width="32px"
              height="32px"
              @click="clear1"
              plain
            >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
          </div>
          <div class="form-inp free">
            <div class="form-inp free">
              <v-select
                single-line
                v-model="CHK_NEWTIME"
                :items="CHK_TIME"
                item-value="value"
                item-text="text"
                outlined
                label="상담일자"
                multiple
                hide-details
                v-on:change="[chgChkTime(),chkNoTime()]"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 4" small>
                    <span>{{ item.text }}</span>
                    <template>
                      <v-btn
                        icon
                        x-small
                        class="svg-close20 ml-1"
                        v-show="dropItemRemove === true"
                        @click.stop="removeDropTime(item)"
                      ></v-btn>
                    </template>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text text-caption">
                    ...
                  </span>
                </template>
              </v-select>
            </div>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllCheck')" outlined class="btn-default ml-2" @click="allClick"
              >전체선택</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllUncheck')" outlined class="btn-default ml-2" @click="[allCloseClick(),chkNoTime()]"
              >전체해제</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnWork')" outlined class="btn-default ml-2" @click="selectTime"
              >업무시간선택</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnTurn')" outlined class="btn-default ml-2" @click="selectTurn"
              >선택반전</v-btn
            >
          </div>
            <div class="form-inp free ml-2">
            <v-text-field
              class="form-inp sm ml-2"
              name="CALL_TIME"
              v-model="CALL_TIME"
              label="녹음시간"
              outlined
              hide-details
            ></v-text-field>
            </div>
            <span>  초</span>
            <div class="form-inp sm ml-2">
              <v-select
                v-model="SEL_NEWUPDOWN"
                :items="SEL_UPDOWN"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                label="범위"
              >
                <template v-slot:label>
                  범위
                </template>
              </v-select>
            </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" v-on:click="search">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 상담이력조회 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        상담이력관리 목록
        <div class="ml-auto">
          <v-text-field
            class="form-inp sm ml-2"
            name="SEL_DATA_NUM"
            v-model="SEL_DATA_NUM"
            label="엑셀 데이터 개수"
            outlined
            hide-details
          ></v-text-field>
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')"
            outlined
            class="btn-point ml-2"
            @click="search('Y')"
          >
            엑셀다운로드
          </v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="320px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          item-key="ROW_NUMBER"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          calculate-widths
          @page-count="pageCount = $event"
          @click:row="detail"
          id="consultListTable"
          single-select
          no-data-text="상담이력이 없습니다."
          v-model="SELECTROW"
          :item-class="isActiveRow"
          fixed-header
        >
          <template v-slot:item.CNSL_DATE="{ item }">
            <div>{{ dateFormat(item.CNSL_DATE) }}</div>
          </template>
          <template v-slot:item.CNSL_BEGIN_TIME="{ item }">
            <div>{{ timeFormat(item.CNSL_BEGIN_TIME) }}</div>
          </template>
          <template v-slot:item.CUST_NO="{ item }">
            <div>{{ juminFormat(item.CUST_NO) }}</div>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>

        <!--다운로드 받을 테이블-->
        <v-data-table
          dense
          height="0px"
          :headers="gridDataHeaders"
          :items="downGridDataText"
          single-select
          item-key="ROW_NUMBER"
          :items-per-page="pagination.rowsPerPage"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          id="downTableMng"
        >
        </v-data-table>
        <!--보이지 않는 테이블-->
      </div>
    </div>
    <!--// 고객상세정보 -->
    <div calss>
      <v-dialog
        max-width="1400"
        max-height="1400"
        v-model="dialogMP02"
        content-class="square-modal"
      >
        <dialog-MP02
          headerTitle="사용자정보"
          @hide="hideDialog('MP02')"
          :userInfo="userInfo"
          @input="userInfo = $event"
          @selectUser="[hideDialog('MP02'), setUser()]"
        ></dialog-MP02>
        <template v-slot:body></template>
      </v-dialog>
    </div>

    <div calss>
      <v-dialog
        max-width="1400"
        max-height="1400"
        v-model="dialogMP01"
        content-class="square-modal"
      >
        <dialog-MP01
          headerTitle="고객정보"
          @hide="hideDialog('MP01')"
          :cusInfo="cusInfo"
          @input="cusInfo = $event"
          @selectCus="[hideDialog('MP01'), setCus()]"
        ></dialog-MP01>
        <template v-slot:body></template>
      </v-dialog>
    </div>
    <!--// 상담이력조회 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">녹취재생</h2>
      <v-card-text>
        <v-row class="black--text">
          <audio 
            controls="" 
            autoplay="true" 
            name="media"
            :src="REC_CALL"
            type="audio/mpeg"
          >
          </audio>
        </v-row>
      </v-card-text>
    </div>
    <!-- 상담이력상세정보 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        상담이력 상세정보
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCancle')" outlined class="btn-etc ml-2" v-on:click="cancel">취소</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnReqAccess')" outlined class="btn-point ml-2" @click="showConfirm"
            >승인요청</v-btn
          >
        </div>
      </h2>
      <div class="box-ct">
        <div class="table-form">
          <table>
            <caption class="hide">
              상담이력 상세정보
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">상담시작일자</th>
                <td>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(rowData.CNSL_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu2 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담시작시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(rowData.CNSL_BEGIN_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">콜유형</th>
                <td>
                  <v-select
                    class="form-inp full"
                    :items="
                      mixin_common_code_get(this.common_code, 'PLT007', '전체')
                    "
                    outlined
                    hide-details
                    label=""
                    placeholder="선택하세요"
                    v-model="rowData.CALL_TY"
                  ></v-select>
                </td>
                <th scope="row">인가번호</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    :disabled="true"
                    value="인가번호"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">고객전화번호</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    :disabled="true"
                    outlined
                    hide-details
                    :value="phoneHyphen(rowData.CUST_TEL_NO)"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담종료일자</th>
                <td>
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(rowData.CNSL_EOT_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu3 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담종료시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(rowData.CNSL_EOT_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">사용자명</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="rowData.CSLT_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">고객명</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="rowData.CUST_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">발신번호</th>
                <td>
                  <v-text-field
                    class="form-inp fullsm"
                    v-model="rowData.SEND_MAN_NO"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담유형</th>
                <td>
                  <v-select
                    class="form-inp full"
                    :items="detailSrhCnslTypCd"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    label=""
                    @change="
                      changeDetailCounselType(
                        selected_channel,
                        rowData.CNSL_TYP_CD,
                        '1'
                      )
                    "
                    v-model="rowData.CNSL_TYP_CD"
                  ></v-select>
                </td>
                <td>
                  <v-select
                    class="form-inp full"
                    :items="detailSrhCnslTypCd2"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    label=""
                    @change="
                      changeDetailCounselType(
                        selected_channel,
                        rowData.CNSL_TYP_CD_2,
                        '2'
                      )
                    "
                    v-model="rowData.CNSL_TYP_CD_2"
                  ></v-select>
                </td>
                <td>
                  <v-select
                    class="form-inp full"
                    :items="detailSrhCnslTypCd3"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    label=""
                    @change="
                      changeDetailCounselType(
                        selected_channel,
                        rowData.CNSL_TYP_CD_3,
                        '3'
                      )
                    "
                    v-model="rowData.CNSL_TYP_CD_3"
                  ></v-select>
                </td>
                <td colspan="2">
                  <v-select
                    class="form-inp full"
                    :items="detailSrhCnslTypCd4"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    label=""
                    v-model="rowData.CNSL_TYP_CD_4"
                  ></v-select>
                </td>
                <th scope="row">녹취파일명</th>
                <td colspan="3">
                  <v-text-field
                    class="form-inp full"
                    :value="rowData.RDWT_FILE_NM"
                    v-model="RDWT_FILE_NM"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row" rowspan="2">비고</th>
                <td rowspan="2" colspan="5">
                  <!-- <v-textarea
                    counter
                    label
                    id="detailCnslCntn"
                    v-model="rowData.CNSL_CNTN"

                    no-resize outlined
                  ></v-textarea> -->
                  <v-textarea
                    class="textarea-type0"
                    label
                    :value="rowData.CNSL_CNTN"
                    v-model="CNSL_CNTN"
                    no-resize
                    outlined
                  ></v-textarea>
                </td>
                <th scope="row">완료여부</th>
                <td colspan="3">
                  <v-select
                    class="form-inp full"
                    :items="
                      mixin_common_code_get(this.common_code, 'CO001', '선택')
                    "
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="rowData.PROC_CODE_LCLS"
                  ></v-select>
                </td>
              </tr>
              <tr>
                <th scope="row">미완료사유</th>
                <td colspan="3">
                  <v-select
                    class="form-inp full"
                    :items="
                      mixin_common_code_get(this.common_code, 'CO002', '선택')
                    "
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="rowData.PROC_CODE_MDCLS"
                  ></v-select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--// 고객상세정보 -->
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";
import XLSX from "xlsx";
import dialogMP01 from "./MP01.vue";
import dialogMP02 from "./MP02.vue";
import api from "@/store/apiUtil.js";
export default {
  name: "MENU_M110202", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    dialogMP01,
    dialogMP02,
  },
  data: () => ({
    CNSL_TYP_CD: "",
    CNSL_TYP_CD_2: "",
    CNSL_TYP_CD_3: "",
    CNSL_TYP_CD_4: "",
    PROC_MDCLS_NM: "",
    CHNG_REQ_CNTN: "",
    CNSL_CNTN: "",
    RDWT_FILE_NM: "",
    CALL_TY: "",
    dialogMP01: false,
    dialogMP02: false,
    cusInfo: {},
    userInfo: {},
    params: { userId: "", userNm: "", aspNewcustKey: "" },
    common_code: [],
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    timeStep: "10:10",
    selected_channel: "",
    calltypcd: "",
    cnsltypcd: "",
    cnsltypcd2: "",
    cnsltypcd3: "",
    cnsltypcd4: "",
    userId: "",
    userNm: "",
    userId1: "",
    userNm1: "",
    userNick1: "",
    selected_userGroup: "",
    srhCallTypCd: [], //콜유형
    srhCnslTypCd: [], //상담유형
    srhCnslTypCd2: [], //상담유형2
    srhCnslTypCd3: [], //상담유형3
    srhCnslTypCd4: [], //상담유형4
    detailSrhCnslTypCd: [], //상담유형
    detailSrhCnslTypCd2: [], //상담유형2
    detailSrhCnslTypCd3: [], //상담유형3
    detailSrhCnslTypCd4: [], //상담유형4
    rowData: {}, //상세내역정보
    dates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    userGroup: [], //사용자그룹

    gridDataHeaders: [
      {
        text: "NO",
        value: "ROW_NUMBER",
        align: "center",
        width: "70px",
        sortable: true,
      },
      {
        text: "상담일자",
        value: "CNSL_DATE",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "상담시작시간",
        value: "CNSL_BEGIN_TIME",
        align: "center",
        sortable: true,
        width: "120px",
      },
      {
        text: "통화시간",
        value: "TEL_TIME",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "콜유형",
        value: "CALL_TY_NM",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "콜기준여부",
        value: "RDWT_SEND_YN",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "통화자",
        value: "PROC_CODE_SCLS_NM",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "상담원명",
        value: "CSLT_NM",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "고객명",
        value: "CUST_NM",
        align: "center",
        sortable: true,
        width: "120px",
        cellClass: "ellipse ",
      },
      {
        text: "고객주민번호",
        value: "CUST_NO",
        align: "center",
        sortable: true,
        width: "120px",
      },
      {
        text: "발신자번호",
        value: "SEND_MAN_NO",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "상담사ID",
        value: "CSLT_ID",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "상담유형",
        value: "CNSL_TYP_NM",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "상담유형2",
        value: "CNSL_TYP_NM_2",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "상담유형3",
        value: "CNSL_TYP_NM_3",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "상담유형4",
        value: "CNSL_TYP_NM_4",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "완료여부",
        value: "PROC_NM",
        align: "center",
        sortable: true,
        width: "100px",
      },
      {
        text: "녹취파일명",
        value: "RDWT_FILE_NM",
        align: "center",
        sortable: true,
        width: "100px",
        cellClass: "ellipse ",
      },
      {
        text: "상담내용",
        value: "CNSL_CNTN",
        align: "center",
        sortable: true,
        width: "100px",
        cellClass: "ellipse ",
      },
    ],
    gridDataText: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    totalVisible: 10,
    pagination: { page: 1, rowsPerPage: 100, sortBy: "", descending: "" },
    textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
    textareaValue: "200자 제한!",

    SELECTROW: [],

    CHK_TIME: [
        {
          text: "00시",
          selected: true,
          value: "00",
        },
        {
          text: "01시",
          selected: true,
          value: "01",
        },
        {
          text: "02시",
          selected: true,
          value: "02",
        },
        {
          text: "03시",
          selected: true,
          value: "03",
        },
        {
          text: "04시",
          selected: true,
          value: "04",
        },
        {
          text: "05시",
          selected: true,
          value: "05",
        },
        {
          text: "06시",
          selected: true,
          value: "06",
        },
        {
          text: "07시",
          selected: true,
          value: "07",
        },
        {
          text: "08시",
          selected: true,
          value: "08",
        },
        {
          text: "09시",
          selected: true,
          value: "09",
        },
        {
          text: "10시",
          selected: true,
          value: "10",
        },
        {
          text: "11시",
          selected: true,
          value: "11",
        },
        {
          text: "12시",
          selected: true,
          value: "12",
        },
        {
          text: "13시",
          selected: true,
          value: "13",
        },
        {
          text: "14시",
          selected: true,
          value: "14",
        },
        {
          text: "15시",
          selected: true,
          value: "15",
        },
        {
          text: "16시",
          selected: true,
          value: "16",
        },
        {
          text: "17시",
          selected: true,
          value: "17",
        },
        {
          text: "18시",
          selected: true,
          value: "18",
        },
        {
          text: "19시",
          selected: true,
          value: "19",
        },
        {
          text: "20시",
          selected: true,
          value: "20",
        },
        {
          text: "21시",
          selected: true,
          value: "21",
        },
        {
          text: "22시",
          selected: true,
          value: "22",
        },
        {
          text: "23시",
          selected: true,
          value: "23",
        },
      ],

      CHK_NEWTIME: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      dropItemRemove: true,

      CALL_TIME:'',

      SEL_UPDOWN:[{
        CD: 'UP',
        CD_NM:'이상'
      },
      {
        CD: 'DOWN',
        CD_NM:'이하'
      }
      ],
      SEL_NEWUPDOWN:'',

      downGridDataText:[],
      SEL_DATA_NUM:'',

      REC_CALL:'',
  }),

  async mounted() {
    //공통코드설정
    let codeName = ["PLT007", "CO001", "CO002"];
    this.common_code = await this.mixin_common_code_get_all(codeName);

    //상담유형
    this.srhCnslTypCd = await this.mixin_counsel_type_code_get(
      this.selected_channel,
      "*",
      "전체"
    );
    this.srhCnslTypCd2.push({ CD_NM: "전체", CD: "" });
    this.srhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
    this.srhCnslTypCd4.push({ CD_NM: "전체", CD: "" });

    this.userGroup = await this.mixin_attr_get(
      this.selected_channel,
      "B",
      "전체"
    );
    console.log(this.$store.getters["userStore/GE_USER_ROLE"]);
  },

  methods: {
    btnUser() {
      var user = {
        ASP_CUST_KEY: this.selected_channel,
        USER_ID: this.userId1,
        USER_NM: this.userNm1,
        USER_NICK: this.userNick1,
      };
      this.userInfo = user;
    },
    setUser() {
      this.userId1 = this.userInfo.USER_ID;
      this.userNm1 = this.userInfo.USER_NM;
      this.userNick1 = this.userInfo.USER_NICK;
    },
    btnCus() {
      var cust = {
        ASP_CUST_KEY: this.selected_channel,
        CUST_NO: this.userId,
        CUST_NM: this.userNm,
      };
      this.cusInfo = cust;
    },
    setCus() {
      this.userId = this.cusInfo.CUST_NO;
      this.userNm = this.cusInfo.CUST_NM;
    },
    clear0() {
      this.userId = "";
      this.userNm = "";
    },
    clear1() {
      this.userId1 = "";
      this.userNm1 = "";
      this.userNick1 = "";
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },

    allowedStep: (m) => m % 10 === 0,
    phoneHyphen(number) {
      //핸드폰 번호 형식 만들기
      if (!number) return "";
      var phoneNum = number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      return phoneNum;
    },
    dateFormat(date) {
      //날짜 포멧
      if (!date) return "";
      var newDate = "";

      //공백제거
      date = date.replace(/\s/gi, "");

      if (date.length == 8) {
        newDate = date.replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3");
        return newDate;
      } else {
        return date;
      }
    },
    juminFormat(num) {
      //주민 번호 형식 만들기
      if (!num) return "";

      var juminNum = num.replace(/-/g, "").replace(/(\d{6})(\d{7})/g, "$1-$2");
      return juminNum;
    },

    timeFormat(time) {
      //접수시간 포맷
      var newTime = "";
      if (!time) return "";

      if (time.length == 6) {
        newTime =
          time.substring(0, 2) +
          ":" +
          time.substring(2, 4) +
          ":" +
          time.substring(4, 6);
        return newTime;
      } else {
        //추후 6자리 외의 데이터가 있을 경우 수정
        return time;
      }
    },

    showDialog(type) {
      if (this.selected_channel == "" || this.selected_channel == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }

      //팝업 파라미터 설정
      this.popupParams;
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },

    //상담유형ChangeEvent
    async changeCounselTypeKey(asp_cust_key, spst_counsel_typ_cd, data_type) {
      if (data_type === "0") {
        this.srhCnslTypCd = await this.mixin_counsel_type_code_get(
          asp_cust_key,
          "*",
          "전체"
        );
        this.cnsltypcd2 = "";
        this.cnsltypcd3 = "";
        this.cnsltypcd4 = "";
      }
      if (data_type === "1") {
        this.cnsltypcd2 = "";
        this.cnsltypcd3 = "";
        this.cnsltypcd4 = "";
        this.srhCnslTypCd2 = [];
        this.srhCnslTypCd3 = [];
        this.srhCnslTypCd4 = [];
        this.srhCnslTypCd2.push({ CD_NM: "전체", CD: "" });
        this.srhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
        this.srhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.srhCnslTypCd2 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      } else if (data_type === "2") {
        this.cnsltypcd3 = "";
        this.cnsltypcd4 = "";
        this.srhCnslTypCd3 = [];
        this.srhCnslTypCd4 = [];
        this.srhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
        this.srhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.srhCnslTypCd3 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      } else if (data_type === "3") {
        this.cnsltypcd4 = "";
        this.srhCnslTypCd4 = [];
        this.srhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.srhCnslTypCd4 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      }
    },
    //상세정보 상담유형 세팅
    async changeDetailCounselType(
      asp_cust_key,
      spst_counsel_typ_cd,
      data_type
    ) {
      if (data_type === "1") {
        this.detailSrhCnslTypCd2 = [];
        this.detailSrhCnslTypCd3 = [];
        this.detailSrhCnslTypCd4 = [];
        this.detailSrhCnslTypCd2.push({ CD_NM: "전체", CD: "" });
        this.detailSrhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
        this.detailSrhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.detailSrhCnslTypCd2 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      } else if (data_type === "2") {
        this.detailSrhCnslTypCd3 = [];
        this.detailSrhCnslTypCd4 = [];
        this.detailSrhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
        this.detailSrhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.detailSrhCnslTypCd3 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      } else if (data_type === "3") {
        this.detailSrhCnslTypCd4 = [];
        this.detailSrhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
        if (spst_counsel_typ_cd !== "") {
          this.detailSrhCnslTypCd4 = await this.mixin_counsel_type_code_get(
            asp_cust_key,
            spst_counsel_typ_cd,
            "전체"
          );
        }
      }
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    showConfirm() {
      if (this.rowData.length == 0) {
        this.warningAlert();
        return;
      }

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "내용을 저장하시겠습니까?",
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYes,
        callNo: this.callNo,
      });
    },
    callYes() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.approval();
    },
    callNo() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    exportExcel(table_id, file_name) {
      //mixin에 셋팅된 method 호출
      this.table_id = table_id;
      this.file_name = file_name;
      this.mixin_common_exportExcel();
    },

    //년월로 변환
    yyyyMMdd: function (value) {
      // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
      if (value == "") return "";

      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      var js_date = new Date(value);

      // 연도, 월, 일 추출
      var year = js_date.getFullYear();
      var month = js_date.getMonth() + 1;
      var day = js_date.getDate();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      // 최종 포맷 (ex - '2021-10-08')
      return year + "" + month + "" + day;
    },

    warningAlert(text) {
      let alertText = "상세조회후 변경 및 승인요청이가능합니다.";

      if (text != null && text != "") {
        alertText = text;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: alertText,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },
    detail(item, row) {
      //변경 전 콤보박스 값들 세팅
      this.CALL_TY = item.CALL_TY;
      this.CNSL_TYP_CD = item.CNSL_TYP_CD;
      this.CNSL_TYP_CD_2 = item.CNSL_TYP_CD_2;
      this.CNSL_TYP_CD_3 = item.CNSL_TYP_CD_3;
      this.CNSL_TYP_CD_4 = item.CNSL_TYP_CD_4;

      this.rowData = item;

      this.CNSL_CNTN = this.rowData.CNSL_CNTN;
      this.RDWT_FILE_NM = this.rowData.RDWT_FILE_NM;

      this.REC_CALL = "https://hkipcc.co.kr/hdd2/rec/" + item.RDWT_FILE_NM + ".mp3";
    },

    isActiveRow(item) {
      const activeClass =
        item.CNSL_HIST_NO === this.rowData.CNSL_HIST_NO ? "active" : "";
      return activeClass;
    },

    //취소
    cancel() {
      this.SELECTROW = [];
      this.rowData = {};
    },
    async setSrhDetailCnslType() {
      this.detailSrhCnslTypCd = [];
      this.detailSrhCnslTypCd2 = [];
      this.detailSrhCnslTypCd3 = [];
      this.detailSrhCnslTypCd4 = [];
      this.detailSrhCnslTypCd = await this.mixin_counsel_type_code_get(
        this.selected_channel,
        "*",
        "전체"
      );
      this.detailSrhCnslTypCd2.push({ CD_NM: "전체", CD: "" });
      this.detailSrhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
      this.detailSrhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
    },

    async setSrhCnslType() {
      this.srhCnslTypCd = [];
      this.srhCnslTypCd2 = [];
      this.srhCnslTypCd3 = [];
      this.srhCnslTypCd4 = [];
      this.srhCnslTypCd = await this.mixin_counsel_type_code_get(
        this.selected_channel,
        "*",
        "전체"
      );
      this.srhCnslTypCd2.push({ CD_NM: "전체", CD: "" });
      this.srhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
      this.srhCnslTypCd4.push({ CD_NM: "전체", CD: "" });
    },

    //승인요청 버튼클릭
    async approval() {
      // let head = '';
      // let dataa = '';
      this.URLData = "/api/phone/history/manage/modify";
      this.HEADER_SERVICE = "phone.history.manage";
      this.HEADER_METHOD = "modify";
      this.HEADER_TYPE = "BIZ_SERVICE";

      console.log("콜유형 변경전=" + this.CALL_TY);
      console.log("콜유형 변경후=" + this.rowData.CALL_TY);
      console.log("파일명 변경전=" + this.rowData.RDWT_FILE_NM);
      console.log("파일명 변경후=" + this.RDWT_FILE_NM);
      console.log("비고 변경전=" + this.rowData.CNSL_CNTN);
      console.log("비고 변경후=" + this.CNSL_CNTN);
      console.log("상담유형 변경전=" + this.CNSL_TYP_CD);
      console.log("상담유형 변경후=" + this.rowData.CNSL_TYP_CD);

      if (this.rowData.CNSL_CNTN != this.CNSL_CNTN) {
        this.CHNG_REQ_CNTN = "00001";
      } else {
        this.CHNG_REQ_CNTN = "00002";
      }
      // return;
      //api 호출 부분
      await api
        .post(
          this.URLData,
          {
            ASP_NEWCUST_KEY: this.rowData.ASP_NEWCUST_KEY,
            CALL_TY: this.rowData.CALL_TY,
            CNSL_TYP_CD: this.rowData.CNSL_TYP_CD,
            CNSL_TYP_CD_2: this.rowData.CNSL_TYP_CD_2,
            CNSL_TYP_CD_3: this.rowData.CNSL_TYP_CD_3,
            CNSL_TYP_CD_4: this.rowData.CNSL_TYP_CD_4,
            CUST_NM: this.rowData.CUST_NM,
            REQ_CSLT_ID: this.rowData.CSLT_ID,
            CNSL_HIST_NO: this.rowData.CNSL_HIST_NO,

            BF_RDWT_FILE_NM: this.rowData.RDWT_FILE_NM,
            AF_RDWT_FILE_NM: this.RDWT_FILE_NM, //after

            BF_CNSL_CNTN: this.rowData.CNSL_CNTN,
            AF_CNSL_CNTN: this.CNSL_CNTN,

            CHNG_REQ_CNTN: this.CHNG_REQ_CNTN,

            BF_UN_PROC_RESN: this.PROC_MDCLS_NM, //미완료사유
            AF_UN_PROC_RESN: this.rowData.PROC_CODE_MDCLS,

            CHNG_MAN: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            REG_MAN: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            REQ_CSLT_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,

            CENT_TY: "G", //팔레트 1.0에서도 G로 하드코딩
            REG_DTIM: this.rowData.REG_DTIM,
            TRANS_STATUS: "N",

            BF_CALL_TY: this.CALL_TY,
            AF_CALL_TY: this.rowData.CALL_TY,

            BF_CNSL_TYP_CD: this.CNSL_TYP_CD,
            BF_CNSL_TYP_CD_2: this.CNSL_TYP_CD_2,
            BF_CNSL_TYP_CD_3: this.CNSL_TYP_CD_3,
            BF_CNSL_TYP_CD_4: this.CNSL_TYP_CD_4,

            AF_CNSL_TYP_CD: this.rowData.CNSL_TYP_CD,
            AF_CNSL_TYP_CD_2: this.rowData.CNSL_TYP_CD_2,
            AF_CNSL_TYP_CD_3: this.rowData.CNSL_TYP_CD_3,
            AF_CNSL_TYP_CD_4: this.rowData.CNSL_TYP_CD_4,

            BF_PROC_RSLT: this.PROC_CODE_LCLS,
            AF_PROC_RSLT: this.rowData.PROC_CODE_LCLS,

            SEQ: "",
          },
          {
            head: {
              SERVICE: this.HEADER_SERVICE,
              METHOD: this.HEADER_METHOD,
              TYPE: this.HEADER_TYPE,
              ROW_CNT: "20",
              PAGES_CNT: "1",
            },
          }
        ) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
        .then((response) => {
          // console.log(JSON.stringify(response.data.DATA))
          // let jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
          // this.gridDataText = jsonObj;

          //console.log(JSON.stringify(response.data));
          // head = JSON.stringify(response.data.HEADER);
          // dataa = JSON.stringify(response.data.DATA);
          this.common_alert("정상 처리되었습니다.");
          this.search('N');
          this.success();
        }) //api 호출 성공 이후 수행
        .catch((err) => {
          this.fail();
        }); //api호출 에러 작업 수행
      // this.HEADERResult = head;
      // this.DATAResult = dataa;
      //결과값 보여주기
    },

    async search(DOWN_YN) {
      if (this.selected_channel == "" || this.selected_channel == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }

      let DATA_NUM = this.pagination.rowsPerPage;
      if(DOWN_YN == 'Y'){
        if (this.gridDataText.length == 0) {
          this.showAlert("상담이력 조회 후 다운로드할 수 있습니다.");
          return;
        }
        if(this.SEL_DATA_NUM != '0' && this.SEL_DATA_NUM != '' && this.SEL_DATA_NUM != undefined){
          DATA_NUM = this.SEL_DATA_NUM;
        }
      } else {
        DATA_NUM = this.pagination.rowsPerPage;
      }

      let head = "";
      let dataa = "";
      this.URLData = "/api/phone/history/inqire/list";
      this.HEADER_SERVICE = "phone.history.inqire";
      this.HEADER_METHOD = "list";
      this.HEADER_TYPE = "BIZ_SERVICE";

      await api
        .post(
          this.URLData,
          {
            ASP_NEWCUST_KEY: this.selected_channel,
            SEARCH_DT_FROM: this.yyyyMMdd(this.dates[0]),
            SEARCH_DT_TO: this.yyyyMMdd(this.dates[1]),
            CALL_TY: this.calltypcd,
            CNSL_TYP_CD: this.cnsltypcd,
            CNSL_TYP_CD_2: this.cnsltypcd2,
            CNSL_TYP_CD_3: this.cnsltypcd3,
            CNSL_TYP_CD_4: this.cnsltypcd4,
            CUST_NO: this.userId,
            CUST_NM: this.userNm,
            TM_KIND: this.selected_userGroup, //사용자그룹
            CSLT_ID: this.userId1,
            CSLT_NM: this.userNm1,
            CSLT_NICK: this.userNick1,
            SEARCH_TIME_0: this.CHK_NEWTIME[0],
            SEARCH_TIME_1: this.CHK_NEWTIME[1],
            SEARCH_TIME_2: this.CHK_NEWTIME[2],
            SEARCH_TIME_3: this.CHK_NEWTIME[3],
            SEARCH_TIME_4: this.CHK_NEWTIME[4],
            SEARCH_TIME_5: this.CHK_NEWTIME[5],
            SEARCH_TIME_6: this.CHK_NEWTIME[6],
            SEARCH_TIME_7: this.CHK_NEWTIME[7],
            SEARCH_TIME_8: this.CHK_NEWTIME[8],
            SEARCH_TIME_9: this.CHK_NEWTIME[9],
            SEARCH_TIME_10: this.CHK_NEWTIME[10],
            SEARCH_TIME_11: this.CHK_NEWTIME[11],
            SEARCH_TIME_12: this.CHK_NEWTIME[12],
            SEARCH_TIME_13: this.CHK_NEWTIME[13],
            SEARCH_TIME_14: this.CHK_NEWTIME[14],
            SEARCH_TIME_15: this.CHK_NEWTIME[15],
            SEARCH_TIME_16: this.CHK_NEWTIME[16],
            SEARCH_TIME_17: this.CHK_NEWTIME[17],
            SEARCH_TIME_18: this.CHK_NEWTIME[18],
            SEARCH_TIME_19: this.CHK_NEWTIME[19],
            SEARCH_TIME_20: this.CHK_NEWTIME[20],
            SEARCH_TIME_21: this.CHK_NEWTIME[21],
            SEARCH_TIME_22: this.CHK_NEWTIME[22],
            SEARCH_TIME_23: this.CHK_NEWTIME[23],
            RANGE: this.SEL_NEWUPDOWN,
            REC_TIME: this.CALL_TIME,
          },
          {
            head: {
              SERVICE: this.HEADER_SERVICE,
              METHOD: this.HEADER_METHOD,
              TYPE: this.HEADER_TYPE,
              ROW_CNT: DATA_NUM,
              PAGES_CNT: this.pagination.page,
            },
          }
        )
        .then((response) => {
          this.rowData = [];

          let jsonObj = JSON.parse(JSON.stringify(response.data.DATA));

          if(DOWN_YN == 'Y'){
            this.downGridDataText = jsonObj;
          } else {
            this.gridDataText = jsonObj;
          }

          this.SELECTROW = [];

          head = JSON.stringify(response.data.HEADER);
          dataa = JSON.stringify(response.data.DATA);
        })
        .catch((err) => {
          alert(err);
        });
      this.HEADERResult = head;
      this.DATAResult = dataa;

      if(DOWN_YN == 'Y'){
        this.exportExcel('downTableMng', '상담이력조회 목록');
      }
    },

    success() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "정상처리 되었습니다.",
        iconClass: "svg-done-lg",
        type: "default",
      });
    },

    fail() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.",
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    
    chgChkTime(){
      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }
     this.CHK_NEWTIME.splice(24,1);
    },

    removeDropTime(item) {
      this.CHK_NEWTIME.splice(parseInt(item.value), 1);

      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }

      this.chkNoTime();
    },

    allClick() {
      this.CHK_NEWTIME = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];
    },

    allCloseClick() {
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
    },

    async selectTime() {
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      await api
        .post(
          "api/statistics/chat/common/start-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.start-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var ST_TIME = response.data.DATA[0].WORK_START_TIME;

          var ST_HOUR = ST_TIME.substr(0, 2);
          this.ST_JOB_TIME = ST_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      await api
        .post(
          "api/statistics/chat/common/end-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.end-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var END_TIME = response.data.DATA[0].WORK_END_TIME;

          var END_HOUR = END_TIME.substr(0, 2);
          this.END_JOB_TIME = END_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];

      for (
        var i = parseInt(this.ST_JOB_TIME);
        i <= parseInt(this.END_JOB_TIME);
        i++
      ) {
        if (i < 10) {
          let DB_TIME = "0" + i;
          this.CHK_NEWTIME[i] = DB_TIME;
        } else {
          this.CHK_NEWTIME[i] = i + "";
        }
      }
    },

    selectTurn() {
      var NEWTIME = [];

      for (var i = 0; i < 24; i++) {
        NEWTIME[i] = this.CHK_NEWTIME[i];
        if (i < 10) {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            let DB_TIME = "0" + i;
            NEWTIME[i] = DB_TIME;
          }
        } else {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            NEWTIME[i] = i + "";
          }
        }
      }
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      this.CHK_NEWTIME = NEWTIME;
    },

  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    popupParams() {
      return (this.params = {
        userId: this.userId,
        userNm: this.userNm,
        asp_cust_key: this.selected_channel,
      });
    },
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  watch: {
    async rowData() {
      console.log(
        "call rowData!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
        this.rowData
      );
      this.setSrhDetailCnslType();
      if (
        this.rowData.CNSL_TYP_CD != "" &&
        this.rowData.CNSL_TYP_CD != undefined
      ) {
        this.changeDetailCounselType(
          this.selected_channel,
          this.rowData.CNSL_TYP_CD,
          "1"
        );
        if (
          this.rowData.CNSL_TYP_CD_2 != "" &&
          this.rowData.CNSL_TYP_CD_2 != undefined
        ) {
          this.changeDetailCounselType(
            this.selected_channel,
            this.rowData.CNSL_TYP_CD_2,
            "2"
          );
          if (
            this.rowData.CNSL_TYP_CD_3 != "" &&
            this.rowData.CNSL_TYP_CD_3 != undefined
          ) {
            this.changeDetailCounselType(
              this.selected_channel,
              this.rowData.CNSL_TYP_CD_3,
              "3"
            );
          }
        }
      }
    },
  },
};
</script>

<style></style>
